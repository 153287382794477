define("discourse/plugins/chat/discourse/components/chat-user-avatar", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse/helpers/concat-class", "discourse/helpers/user-avatar", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _template, _concatClass, _userAvatar, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatUserAvatar = _exports.default = (_class = (_class2 = class ChatUserAvatar extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
    }
    get avatar() {
      return (0, _template.htmlSafe)((0, _userAvatar.renderAvatar)(this.args.user, {
        imageSize: this.avatarSize
      }));
    }
    get interactive() {
      return this.args.interactive ?? true;
    }
    get avatarSize() {
      return this.args.avatarSize || "tiny";
    }
    get showPresence() {
      return this.args.showPresence ?? true;
    }
    get isOnline() {
      const users1 = (this.args.chat || this.chat).presenceChannel?.users;
      return this.showPresence && !!users1?.find(_ref => {
        let {
          id: id1,
          username: username1
        } = _ref;
        return this.args.user?.id === id1 || this.args.user?.username === username1;
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class={{concatClass "chat-user-avatar" (if this.isOnline "is-online")}}
        data-username={{@user.username}}
      >
        {{#if this.interactive}}
          <div
            role="button"
            class="chat-user-avatar__container clickable"
            data-user-card={{@user.username}}
          >
            {{this.avatar}}
          </div>
        {{else}}
          {{this.avatar}}
        {{/if}}
      </div>
    
  */
  {
    "id": "HMsa+GSZ",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"chat-user-avatar\",[52,[30,0,[\"isOnline\"]],\"is-online\"]],null]],[15,\"data-username\",[30,1,[\"username\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"interactive\"]],[[[1,\"        \"],[10,0],[14,\"role\",\"button\"],[14,0,\"chat-user-avatar__container clickable\"],[15,\"data-user-card\",[30,1,[\"username\"]]],[12],[1,\"\\n          \"],[1,[30,0,[\"avatar\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"avatar\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@user\"],false,[\"if\"]]",
    "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-user-avatar.js",
    "scope": () => [_concatClass.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});