define("discourse/plugins/chat/discourse/components/chat-drawer/header/title", ["exports", "@glimmer/component", "discourse/helpers/replace-emoji", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _replaceEmoji, _dIcon, _discourseI18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatDrawerHeaderTitle extends _component.default {
    get headerTitle() {
      if (this.args.title) {
        return _discourseI18n.default.t(this.args.title);
      }
      return (0, _replaceEmoji.default)(this.args.translatedTitle);
    }
    get showChannel() {
      return this.args.channelName ?? false;
    }
    get showIcon() {
      return this.args.icon ?? false;
    }
  }
  _exports.default = ChatDrawerHeaderTitle;
  _class = ChatDrawerHeaderTitle;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <span class="chat-drawer-header__title">
        <div class="chat-drawer-header__top-line">
          <span class="chat-drawer-header__icon">
            {{#if this.showIcon}}
              {{icon @icon}}
            {{/if}}
          </span>
  
          <span class="chat-drawer-header__title-text">{{this.headerTitle}}</span>
  
          {{#if this.showChannel}}
            <span class="chat-drawer-header__divider">-</span>
            <span class="chat-drawer-header__channel-name">{{@channelName}}</span>
          {{/if}}
        </div>
      </span>
    
  */
  {
    "id": "NA8zkGFA",
    "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"chat-drawer-header__title\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-drawer-header__top-line\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"chat-drawer-header__icon\"],[12],[1,\"\\n\"],[41,[30,0,[\"showIcon\"]],[[[1,\"            \"],[1,[28,[32,0],[[30,1]],null]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"chat-drawer-header__title-text\"],[12],[1,[30,0,[\"headerTitle\"]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showChannel\"]],[[[1,\"          \"],[10,1],[14,0,\"chat-drawer-header__divider\"],[12],[1,\"-\"],[13],[1,\"\\n          \"],[10,1],[14,0,\"chat-drawer-header__channel-name\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@icon\",\"@channelName\"],false,[\"if\"]]",
    "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-drawer/header/title.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), _class);
});