define("discourse/plugins/chat/discourse/components/chat-channel-members", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/runloop", "@ember/service", "ember-modifier", "discourse/lib/is-element-in-viewport", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-i18n", "truth-helpers/helpers/gt", "discourse/plugins/chat/discourse/components/chat-user-info", "discourse/plugins/chat/discourse/components/dc-filter-input", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _helper, _object, _runloop, _service, _emberModifier, _isElementInViewport, _environment, _debounce, _discourseI18n, _gt, _chatUserInfo, _dcFilterInput, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChannelMembers = _exports.default = (_class = (_class2 = class ChatChannelMembers extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "loadingSlider", _descriptor3, this);
      _initializerDefineProperty(this, "filter", _descriptor4, this);
      _defineProperty(this, "filterPlaceholder", _discourseI18n.default.t("chat.members_view.filter_placeholder"));
      _defineProperty(this, "noMembershipsFoundLabel", _discourseI18n.default.t("chat.channel.no_memberships_found"));
      _defineProperty(this, "noMembershipsLabel", _discourseI18n.default.t("chat.channel.no_memberships"));
      _defineProperty(this, "focusInput", (0, _emberModifier.modifier)(element1 => {
        (0, _runloop.schedule)("afterRender", () => {
          element1.focus();
        });
      }));
      _defineProperty(this, "fill", (0, _emberModifier.modifier)(element1 => {
        this.resizeObserver = new ResizeObserver(() => {
          if ((0, _isElementInViewport.default)(element1)) {
            this.load();
          }
        });
        this.resizeObserver.observe(element1);
        return () => {
          this.resizeObserver.disconnect();
        };
      }));
      _defineProperty(this, "loadMore", (0, _emberModifier.modifier)(element1 => {
        this.intersectionObserver = new IntersectionObserver(this.load);
        this.intersectionObserver.observe(element1);
        return () => {
          this.intersectionObserver.disconnect();
        };
      }));
    }
    get noResults() {
      return this.members.fetchedOnce && !this.members.loading;
    }
    get members() {
      const params1 = {};
      if (this.filter?.length) {
        params1.username = this.filter;
      }
      return this.chatApi.listChannelMemberships(this.args.channel.id, params1);
    }
    load() {
      (0, _debounce.default)(this, this.debouncedLoad, _environment.INPUT_DELAY);
    }
    mutFilter(event1) {
      this.filter = event1.target.value;
      this.load();
    }
    async debouncedLoad() {
      this.loadingSlider.transitionStarted();
      await this.members.load({
        limit: 20
      });
      this.loadingSlider.transitionEnded();
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-channel-members">
        <DcFilterInput
          @class="chat-channel-members__filter"
          @filterAction={{this.mutFilter}}
          @icons={{hash right="search"}}
          placeholder={{this.filterPlaceholder}}
          {{this.focusInput}}
        />
  
        {{#if (gt @channel.membershipsCount 0)}}
          <ul class="chat-channel-members__list" {{this.fill}}>
            {{#each this.members as |membership|}}
              <li class="chat-channel-members__list-item">
                <ChatUserInfo @user={{membership.user}} @avatarSize="tiny" />
              </li>
            {{else}}
              {{#if this.noResults}}
                <li
                  class="chat-channel-members__list-item -no-results alert alert-info"
                >
                  {{this.noMembershipsFoundLabel}}
                </li>
              {{/if}}
            {{/each}}
          </ul>
  
          <div {{this.loadMore}}>
            <br />
          </div>
        {{else}}
          <p class="alert alert-info">
            {{this.noMembershipsLabel}}
          </p>
        {{/if}}
      </div>
    
  */
  {
    "id": "t0+2j7y9",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-channel-members\"],[12],[1,\"\\n      \"],[8,[32,0],[[16,\"placeholder\",[30,0,[\"filterPlaceholder\"]]],[4,[30,0,[\"focusInput\"]],null,null]],[[\"@class\",\"@filterAction\",\"@icons\"],[\"chat-channel-members__filter\",[30,0,[\"mutFilter\"]],[28,[32,1],null,[[\"right\"],[\"search\"]]]]],null],[1,\"\\n\\n\"],[41,[28,[32,2],[[30,1,[\"membershipsCount\"]],0],null],[[[1,\"        \"],[11,\"ul\"],[24,0,\"chat-channel-members__list\"],[4,[30,0,[\"fill\"]],null,null],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"members\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[14,0,\"chat-channel-members__list-item\"],[12],[1,\"\\n              \"],[8,[32,3],null,[[\"@user\",\"@avatarSize\"],[[30,2,[\"user\"]],\"tiny\"]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],[[[41,[30,0,[\"noResults\"]],[[[1,\"              \"],[10,\"li\"],[14,0,\"chat-channel-members__list-item -no-results alert alert-info\"],[12],[1,\"\\n                \"],[1,[30,0,[\"noMembershipsFoundLabel\"]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"        \"],[13],[1,\"\\n\\n        \"],[11,0],[4,[30,0,[\"loadMore\"]],null,null],[12],[1,\"\\n          \"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[14,0,\"alert alert-info\"],[12],[1,\"\\n          \"],[1,[30,0,[\"noMembershipsLabel\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"membership\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-members.js",
    "scope": () => [_dcFilterInput.default, _helper.hash, _gt.default, _chatUserInfo.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadingSlider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "members", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "members"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "load", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mutFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mutFilter"), _class.prototype)), _class);
});